import React, { useRef } from 'react';
import './index.css';
import useScrollFixedReveal from '../../../hooks/useScrollFixedReveal';
import LowPolyLines from '../../../components/LowPolyLines/LowPolyLines';

const Skills = () => {
    const containerRef = useRef(null);  // Ensure this is initialized correctly
    const languageRef = useRef(null);
    const frameworksRef = useRef(null);
    const devToolsRef = useRef(null);
    const librariesRef = useRef(null);
    const designToolsRef = useRef(null);
    const scrollContentRef = useRef(null);

    // Now pass these refs to your hook
    useScrollFixedReveal({ containerRef, languageRef, frameworksRef, devToolsRef, librariesRef, designToolsRef });

    return (
        <div ref={containerRef} className="scroll-container">
            <h2 className='scroll-title'>TECHNOLOGIES<span>(02)</span></h2>
  
            <div className='scroll-shape'>
            <LowPolyLines />
            </div>
            <div className="scroll-content">
                <section ref={languageRef}>
                    <div className='scroll-content-container'>
                    <h3>(Languages)</h3>
                    <ul>
                        <li>Java</li>
                        <li>Javascript/HTML/CSS</li>  
                        <li>Python</li>
                        <li>MySQL</li>
                        <li>PostgreSQL</li>
                    </ul>
                    </div>
                </section>
                <section ref={frameworksRef}> 
                <div className='scroll-content-container'> 
                    <h3>(Frameworks)</h3>
                    <ul>
                        <li>React.js</li>
                        <li>Node.js</li>  
                        <li>Express.js</li>
                        <li>Bootstrap</li>
                        <li>Material-UI</li>
                        <li>Tailwind-UI</li>
                    </ul>
                    </div>
                </section>
                
                <section ref={devToolsRef}>
                <div className='scroll-content-container'>
                    <h3>(Dev Tools)</h3>
                    <ul>
                        <li>Github</li>
                        <li>Google Cloud Platform</li>  
                        <li>Digital Ocean</li>
                        <li>VS Code</li>
                        <li>Visual Studio</li>
                        <li>Net Beans</li>
                        <li>MongoDB</li>
                    </ul>
                    </div>
                </section>
                <section ref={librariesRef}>
                <div className='scroll-content-container'>
                    <h3>(Libraries)</h3>
                    <ul>
                        <li>GSAP</li>
                        <li>Locomotive Scroll</li>  
                        <li>JavaFX</li>
                        <li>Three.js</li>
                    </ul>
                    </div>
                </section>
                <section ref={designToolsRef}>
                    <div className='scroll-content-container'>
                    <h3>(Content)</h3>
                    <ul>
                        <li>Strapi</li>
                        <li>Wordpress</li>  
                    </ul>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Skills;



 