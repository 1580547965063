import React, { useState, useRef, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.css';
import useGlitchEffect from '../../hooks/useTextGlitch';
import { useTheme } from '../../Context/ThemeContext'; 
import  gsap  from 'gsap';
import { BlurContext } from '../../Context/BlurContext';
import useGlitchMenu from '../../hooks/useGlitchMenu';
import logo from '../../assets/svgs/CLogo-Outline.svg'

const Navbar = ({ onMenuSelect, aboutRef, workRef, homeRef, contactRef, activeSection}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const { isDarkMode, toggleTheme } = useTheme();
  const menuRef = useRef();
  const toggleButtonRef = useRef();
  const blurRef = useContext(BlurContext);
  const { canvasRef, fillSquares, clearSquares } = useGlitchMenu(); 
  const location = useLocation();
  const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024); 
    const updateMedia = () => {
      setIsMobile(window.innerWidth < 1024);
    };
  
    useEffect(() => {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }, []);

  const refs = {
    home: useRef(null),
    about: useRef(null),
    work: useRef(null), 
    contact: useRef(null)
  };

  const navRefs = {
    home: useRef(null),
    about: useRef(null),
    work: useRef(null),
    contact: useRef(null)
  };


  const { startGlitchEffect, stopGlitchEffect, useGlitchWhenActive } = useGlitchEffect(refs);
  useGlitchWhenActive(activeSection); 

  const handleMenuClick = (sectionRef, path, linkName) => {
    toggleMenu();
    setActiveLink(linkName); 
    if (location.pathname !== path) { 
      navigate(path);
    }

    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 50);
    } else {
      sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  


  const toggleMenu = () => {
    setIsOpen(prevState => {
        const newState = !prevState;
        if (newState) {
            console.log("fill squares called")
            fillSquares();  
        } else {
          console.log("clear squares called")
            clearSquares();  
        }
        return newState;
    });
};


useEffect(() => {
  const navLinks = [refs.home.current, refs.about.current, refs.work.current, refs.contact.current];
  const navContainers = [navRefs.home.current, navRefs.about.current, navRefs.work.current, navRefs.contact.current];
  const tl = gsap.timeline();

  if(!isOpen) {
  gsap.set(Object.values(navRefs), { autoAlpha: 0 }); // Set initial state for fade in/out
  }
  if (isMobile) {
    if (isOpen) {
      tl.to(navContainers, {
        autoAlpha: 1,
        duration: 0.5,
        ease: 'power1.inOut',
        stagger: 0.1,
        onStart: () => {
          navLinks.forEach((link, index) => {
            gsap.to(link, {onStart: startGlitchEffect, onStartParams: [link], delay: index * 0.1});
          });
        }
      }, "<0.5");
    } else {
      tl.to(navContainers, { autoAlpha: 0, duration: 0.3, ease: 'power1.out', stagger: 0.1 }, "<0.1");
      navLinks.forEach(link => {
        gsap.to(link, {onComplete: stopGlitchEffect, onCompleteParams: [link]});
      });
    }
  } else {
    // Ensure all nav containers are fully visible and links are not glitching on non-mobile
    gsap.to(navContainers, { autoAlpha: 1, duration: 0.1 });
    navLinks.forEach(link => stopGlitchEffect(link));
  }
}, [isOpen, startGlitchEffect, stopGlitchEffect, isMobile]);



  
  return (
    <>  
      <nav className="navbar">
        <div className='logo-section'>
          <div className="logo-container">
            <div className="logo">
              <img src={logo}></img>
              </div>
          </div>

          <div className="themeButton">
  <div className="toggle-switch" onClick={toggleTheme}>
    {/* Light mode square */}
    <span className={`square light-mode-square ${!isDarkMode ? 'active' : ''}`}>&#x25A0;</span>
  </div>
</div>

        </div>
        <div className='menu-toggle-container'>
        <button  ref={toggleButtonRef} onClick={toggleMenu} className={`menu-toggle ${isOpen ? 'open' : ''}`}>
        {isOpen ? '( CLOSE )' : '( MENU )'}
</button>
</div>

      
        <div className={isOpen ? "navbar-nav open" : "navbar-nav"}>
        <canvas className={`canvas ${isOpen ? 'open' : ''}`} ref={canvasRef}></canvas>
          <ul>
            <li ref={navRefs.home} className="nav-item">
            <p>(01)</p>
              <div
                id="home-link"
                ref={refs.home}
                className={activeSection === 'home' ? 'nav-link active' : 'nav-link'}
                onClick={() => handleMenuClick(homeRef, '/#home', 'home')}
                onMouseEnter={() => startGlitchEffect(refs.home.current)}
                onMouseLeave={() => stopGlitchEffect(refs.home.current)}
              >
                <h4>HOME</h4>
              </div>
            </li>

            <li ref={navRefs.about} className="nav-item">  
            <p>(02)</p>
              <div
                id="about-link"
                ref={refs.about}
                className={activeSection === 'about' ? 'nav-link active' : 'nav-link'}
                onClick={() => handleMenuClick(aboutRef, '/#about', 'about')}
                onMouseEnter={() => startGlitchEffect(refs.about.current)}
                onMouseLeave={() => stopGlitchEffect(refs.about.current)}
              >
                ABOUT
              </div>
            </li>
            <li ref={navRefs.work} className="nav-item">
            <p>(03)</p>
              <div
                id="work-link"
                ref={refs.work}
                className={activeSection === 'work' ? 'nav-link active' : 'nav-link'}
                onClick={() => handleMenuClick(workRef, '/#work', 'work')}
                onMouseEnter={() => startGlitchEffect(refs.work.current)}
                onMouseLeave={() => stopGlitchEffect(refs.work.current)}
              >
                WORKS
              </div>
            </li>
            <li ref={navRefs.contact} className="nav-item">
            <p>(04)</p>
              <div  
                id="contact-link"
                ref={refs.contact}
                className={activeSection === 'contact' ? 'nav-link active' : 'nav-link'}
                onClick={() => handleMenuClick(contactRef, '/#contact', 'contact')}
                onMouseEnter={() => startGlitchEffect(refs.contact.current)}
                onMouseLeave={() => stopGlitchEffect(refs.contact.current)}
              >
                CONTACT
              </div>
            </li>
          </ul>
        </div>
        
      </nav>
      
       </>
  );
};

export default Navbar;




  