import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce'; // Or write your own debounce function

const useActiveSection = (sectionIds) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(location.hash);

  const debouncedNavigate = useCallback(debounce((newHash) => {
    navigate(newHash, { replace: true });
  }, 300), [navigate]);  // 300 ms debounce period

  useEffect(() => {
    if (window.innerWidth > 768) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && entry.intersectionRatio >= 0.25) {
              const newActiveSection = `#${entry.target.id}`;
              setActiveSection(newActiveSection);
            }
          });
        },
        { threshold: 0.25 }
      );

      sectionIds.forEach((id) => {
        const el = document.getElementById(id);
        if (el) observer.observe(el);
      });

      return () => observer.disconnect();
    }
  }, [sectionIds]);

  useEffect(() => {
    if (activeSection && location.hash !== activeSection) {
      debouncedNavigate(activeSection);
    }
  }, [activeSection, location.hash, debouncedNavigate]);

  return activeSection.replace('#', '');
};

export default useActiveSection;

