import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const HoverImage = ({ src, position }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {

    setOpacity(1);


    return () => {
      setOpacity(0);
    };
  }, []);

  if (!src) return null;

  const imageStyle = {
    position: 'fixed',
    top: position.y + 20, 
    left: position.x + 20,
    pointerEvents: 'none',
    zIndex: 1000,
    width: '400px',
    height: 'auto',
    opacity: opacity,  
    transition: 'opacity 0.5s ease-in-out', 
  };

  return ReactDOM.createPortal(
    <img src={src} alt="" style={imageStyle} />,
    document.body
  );
};

export default HoverImage;

