import React, { useEffect, useState } from 'react';
import './index.css'; 
import mountaint from '../../../assets/images/MountainImage.jpg'


const TimeLocationInfo = ({ currentTime, latitude, longitude }) => (
    <div className='timeLocationInfo'>
        <div className='timeInfo'>
            <div className='time-label'>
            <h4> TIME </h4>
            </div>
            {currentTime.toLocaleString()}
        </div>
        <div className='currentLocation'>
        <div className='loc-label'>
            <h4> LOCATION </h4>
            </div>
            {latitude}<br />
            {longitude}
        </div>
    </div>
);

const MouseInfo = ({ mousePosition }) => (
    <div className='mouse-info'>
        <p>{mousePosition.x}, {mousePosition.y}, {mousePosition.random}</p>
    </div>
);

const Header = () => {
    const latitude = 33.92812110258542;
    const longitude = -96.43893946687734;
    const [currentTime, setCurrentTime] = useState(new Date());
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0, random: Math.random() });

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        const handleMouseMove = (event) => {
            setMousePosition({
                x: event.clientX,
                y: event.clientY,
                random: Math.random().toFixed(2)
            });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            clearInterval(intervalId);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <header>
            <div className='topContainer'>
                <TimeLocationInfo currentTime={currentTime} latitude={latitude} longitude={longitude} />
                <div className='nameInfo'>
                        <h1>COLTON_TOLLETT</h1>
                    </div>
            </div>

            <div className='middleSection'>
                <div className='middleContainer'>
                    <div className='description'>
                    <p>I'm Colton Tollett, a Full-Stack Developer and Graphic Designer with expertise in web development and digital design, currently advancing my HCI studies at Georgia Tech.</p>
                </div>
                </div>
                <div className='middleContainer-2'>

                </div>
            </div>

            <div className='bottomContainer'>
                <div className='bottom-info'>
                    <div className='edition'>
                        <h4>( EDITION )</h4>
                        <ul>
                            <li>_24</li>
                        </ul>
                    </div>
                    <div className='roles'>
                        <h4>( ROLES )</h4>
                        <ul>
                            <li>UX / UI</li>
                            <li>Front_End / Full_Stack</li>
                        </ul>
                    </div>
                </div>
                <MouseInfo mousePosition={mousePosition} />
            </div>
        </header>
    );
};

export default Header;
