import React, { useState } from 'react';
import arrow from '../../../assets/svgs/arrow.svg';
import './index.css';
import GetInTouchSection from '../../../components/GetInTouch/index';
import LowPolyGlobe from '../../../components/Globe';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://formspree.io/f/xdoqoakq', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            
            if (response.ok) {
                alert('Form submitted successfully!');
                setFormData({
                    name: '',
                    email: '',
                    message: ''
                });
            } else {
                alert('There was an error submitting the form.');  
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error sending message.');
        }
    };

    return (
        <>
            <div className='getInTouch'>
                <GetInTouchSection />
            </div>
            <div className='contact-container'>
                <div className='social-section'>
                    <div className='globe-section'>
                        <LowPolyGlobe />
                    </div>
                    <div className='social-section-row'>
                        <div className='social-section-col'>
                            <p><a href="https://github.com/Ctollett" target="_blank" rel="noopener noreferrer">GitHub</a></p>
                            <p><a href="https://www.linkedin.com/in/colton-tollett-050127137/" target="_blank" rel="noopener noreferrer">LinkedIn</a></p>
                        </div>
                        <div className='social-section-col'>
                            <div className='social-section-label'>
                                <h4>(Email)</h4>
                            </div>
                            <p>coltontollett96@gmail.com</p>
                            <div className='social-section-label'>
                                <h4>(Phone)</h4>
                            </div>
                            <p>(1)-580-743-7806</p>
                        </div>
                    </div>
                </div>
                <div className='form-section'>
                    <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                       
                            <input type="text" id="name" name="name" placeholder='Name' value={formData.name} onChange={handleChange} />
                        </div>
                        <div className='form-group'>
                            <input type="email" id="email" name="email" placeholder='Email' value={formData.email} onChange={handleChange} />
                        </div>
                        <div className='form-group'>
                    
                            <textarea id="message" name="message" placeholder='Message' value={formData.message} onChange={handleChange} />
                        </div>
                        <button id="submit-button" type="submit" className='submit-button'>
                        SUBMIT <span className="custom-font-arrow-2">→</span> {/* Replace → with your font's specific arrow character if available */}
                            </button>

                    </form>
                </div>
            </div>
        </>
    );
};

export default ContactForm;

  
