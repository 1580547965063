import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HoverImage from '../../../components/HoverImage/HoverImage';
import './index.css';

// Import your images
import Pareidolia from '../../../assets/images/Pareidolia.png';
import Bitscape from '../../../assets/images/Bitscape/Interface.png';
import Casino from '../../../assets/images/ChoctawNation/casino.jpg';

const WorkMenu = () => {
  const navigate = useNavigate();
  const [hoveredProject, setHoveredProject] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // Handle window resize to update mobile state
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const projects = [
    {
      id: '( 001 )',
      routeId: '001', // Use this for navigation
      name: 'PAREIDOLIA',
      description: 'Full-Stack Development',
      year: '2023',
      image: Pareidolia,
    },
    {
      id: '( 002 )',
      routeId: '002', // Use this for navigation
      name: 'BITSCAPE',
      description: 'Full-Stack Development',
      year: '2023-2024',
      image: Bitscape,
    },
    {
      id: '( 003 )',
      routeId: '003', // Use this for navigation
      name: 'CHOCTAW NATION OF OKLAHOMA',
      description: 'Digital Design',
      year: '2017-2023',
      image: Casino,
    },
  ];
  
  return (
    <div
      className="work-menu"
      onMouseMove={(e) => {
        if (!isMobile && hoveredProject) {
          setMousePosition({ x: e.clientX, y: e.clientY });
        }
      }}
    >
      <div>
        <div className='work-menu-title-section'>
          <h2 className='work-menu-title'>WORK<span>(03)</span></h2>
        </div>
      </div>
  
  {projects.map((project) => (
  <div
    key={project.routeId} 
    className="project-row"
    onClick={() => navigate(`/work/${project.routeId}`)}
    onMouseEnter={() => !isMobile && setHoveredProject(project)}
    onMouseLeave={() => !isMobile && setHoveredProject(null)}
  >
    <div className="project-detail">
      <div className="project-title">
        <div className="project-name-row">
          <h4 className="project-name">{project.name}</h4>
          <p className="project-year">{project.year}</p> {/* Year next to name on desktop */}
          <p className="project-description">{project.description}</p>
        </div>
      </div>
    </div>
  </div>
))}

  
      {hoveredProject && (
        <HoverImage src={hoveredProject.image} position={mousePosition} />
      )}
    </div>
  );
  
};

export default WorkMenu;

