import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import projects, { getProjectById } from '../Projects';
import AboutProject from '../../components/aboutProject/index';
import ImageGrid from '../../components/aboutProject/PhotoComponent/index';
import useScrollToTop from '../../hooks/index';
import './index.css';

const CaseStudy = () => {
    useScrollToTop();
    const { id } = useParams();
    const [project, setProject] = useState(null);

    useEffect(() => {
        const fetchProjectData = async () => {
            const projectData = await getProjectById(id);
            if (projectData) {
                setProject(projectData);
            } else {
                console.log(`No project found with ID ${id}`);
            }
        };

        fetchProjectData();
    }, [id]);

    if (!project) {
        return <div>Loading...</div>;
    }

    const currentIndex = projects.findIndex(p => p.id === id);
    const nextProjectIndex = (currentIndex >= 0 && currentIndex < projects.length - 1) ? currentIndex + 1 : null;
    const nextProject = nextProjectIndex !== null ? projects[nextProjectIndex] : null;

    return (
        <div className="case-study">
           <div className='title-section'>
    <div className='project-id-box'>
        <p>{"project  (" + project.id + ")"}</p>
    </div>
    <h1>{project.title}</h1>
</div>

            <div className='image-section'>
                <img src={project.headerImage} alt={project.title} />
            </div>
            <div className='desc-section'>
                <div className='desc-entries'>
                    <p id='siteLink' className='siteLink'>
                        {project.siteUrl ? (
                            <a href={project.siteUrl} target="_blank" rel="noopener noreferrer">{project.site}</a>
                        ) : project.site}
                    </p>
                    <div className='desc-entry'>
                        <p>Year<div id='line'></div><br/>{project.date}</p>
                    </div>
                    <div className='desc-entry'>
                        <p>Role<div id='line'></div><br/>{project.role}</p>
                    </div>
                    <div className='desc-entry'>
                        <p>Tech<div id='line'></div><br/>
                            <div className='tech-stack'>{project.techStack.map(tech => <span key={tech}>{tech}</span>)}</div>
                        </p>
                    </div>
                </div>
            </div>
            <div className='about-project-section'>
                <AboutProject overview={project.overview} headline={project.headline} />
            </div>
            <div className='project-image-section'>
                <ImageGrid images={project.images} />
            </div>
            {nextProject && (
                <div className='next-project-section'>
                    <div className='next-project-box'>
                        <p>( Next Project )</p>
                       
                    </div>
                    <Link className="next-project-link" to={`/work/${projects[nextProjectIndex].id}`}>
                        <div className='next-project-title'>
                            <h2>{nextProject.title}</h2>
                            <span className='project-arrow'>→</span> {/* Using custom font arrow */}
                        </div>
                    </Link>
                </div>
            )}
        </div>   
    );
}; 

export default CaseStudy;
