import React, { useRef, useEffect, useState } from 'react';
import useGlitchEffect from '../../../hooks/useTextGlitch';
import { gsap } from 'gsap';
import './index.css';

const Card = ({ title, children, isExpanded, toggleCard, onEnter, onLeave }) => {
    const arrowRef = useRef(null);

    return (
        <div className={`card ${isExpanded ? 'expanded' : ''}`}>
            <div className="card-header" onClick={toggleCard} onMouseEnter={onEnter} onMouseLeave={onLeave}>
                <h2 id={`${title.toLowerCase()}-title`}>{title}</h2>
                <button className="toggle-button" ref={arrowRef} aria-label={isExpanded ? 'Collapse' : 'Expand'}>
                <span className="custom-font-arrow">
                    {isExpanded ? '↑' : '↓'}
            </span>
</button>

            </div>
            <div className="card-content">{children}</div>
        </div>
    );
};

const AboutMeSection = () => {
    const [expandedCard, setExpandedCard] = useState(null);
    const { startGlitchEffect, stopGlitchEffect } = useGlitchEffect();

    const toggleCard = (card) => {
        setExpandedCard(expandedCard === card ? null : card);
    };

    return (
        <div className="about-me-section">
            <h2 className="about-title">ABOUT<span>(01)</span></h2>
            <div className="card-container">
                <Card
                    title="EXPERIENCE"
                    isExpanded={expandedCard === 'experience'}
                    toggleCard={() => toggleCard('experience')}
                    onEnter={() => startGlitchEffect('experience-title')}
                    onLeave={() => stopGlitchEffect('experience-title')}
                >
                    <ul className="list">
                       <li><span className="role">Digital Designer</span> <span className="company">Choctaw Casinos and Resorts</span> <span className="dates">(2019 - Present)</span></li>
                       <li><span className="role">Full-Stack Developer</span> <span className="company">Self Employed</span> <span className="dates">(2022 - Present)</span></li>
                       <li><span className="role">Email Marketing Developer</span> <span className="company">Choctaw Tribal Services</span> <span className="dates">(2017 - 2019)</span></li>
                    </ul>

                </Card>
                <Card
  title="EDUCATION"
  isExpanded={expandedCard === 'education'}
  toggleCard={() => toggleCard('education')}
  onEnter={() => startGlitchEffect('education-title')}
  onLeave={() => stopGlitchEffect('education-title')}
>
  <ul className="list">
    <li>
      <span className="role">Masters of Computer Science</span>
      <span className="company">Georgia Institute of Technology</span>
      <span className="dates">(2024 - Present)</span>
    </li>
    <li>
      <span className="role">Bachelors of Computer Science</span>
      <span className="company">Southeastern Oklahoma State University</span>
      <span className="dates">(2022 - 2024)</span>
    </li>
    <li>
      <span className="role">Bachelors of English Composition</span>
      <span className="company">Choctaw Tribal Services</span>
      <span className="dates">(2014 - 2017)</span>
    </li>
  </ul>
</Card>


<Card
    title="SKILLS"
    isExpanded={expandedCard === 'skills'}
    toggleCard={() => toggleCard('skills')}
    onEnter={() => startGlitchEffect('skills-title')}
    onLeave={() => stopGlitchEffect('skills-title')}
>
    <ul className="list">
        <li><span className="role">Full-Stack Dev</span></li>
        <li><span className="role">Front-End Dev</span></li>
        <li><span className="role">UI/Digital Design</span></li>
        <li><span className="role">UX Research</span></li>
    </ul>
</Card>
            </div>
        </div>
    );
};

export default AboutMeSection;







    
  
   
    