import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import './index.css'; // Import the CSS file

const LowPolyLines = () => {
    const mountRef = useRef(null);
    const shapeRef = useRef();

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, mountRef.current.clientWidth / mountRef.current.clientHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ alpha: true });

        renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
        renderer.setClearColor(0x6100FF, 0); // Background color transparent

        mountRef.current.appendChild(renderer.domElement);
        camera.position.z = 3.5;

        // Define custom geometry for a cube-like shape with an extra edge
        const geometry = new THREE.BufferGeometry();
        const vertices = new Float32Array([
            // Define vertices for the custom shape
            -1, -1, 1,  // 0
             1, -1, 1,  // 1
             1,  1, 1,  // 2
            -1,  1, 1,  // 3
            -1, -1, -1, // 4
             1, -1, -1, // 5
             1,  1, -1, // 6
            -1,  1, -1, // 7
             0,  0,  2  // 8 (extra vertex for the additional edge)
        ]);

        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

        const indices = new Uint16Array([
            // Front face
            0, 1, 2,
            2, 3, 0,
            // Back face
            4, 5, 6,
            6, 7, 4,
            // Top face
            3, 2, 6,
            6, 7, 3,
            // Bottom face
            0, 1, 5,
            5, 4, 0,
            // Left face
            0, 3, 7,
            7, 4, 0,
            // Right face
            1, 2, 6,
            6, 5, 1,
            // Extra face with the additional vertex
            0, 1, 8,
            1, 2, 8,
            2, 3, 8,
            3, 0, 8
        ]);

        geometry.setIndex(new THREE.BufferAttribute(indices, 1));
        geometry.computeVertexNormals();

        const material = new THREE.MeshBasicMaterial({ color: 0x6100FF, wireframe: true });
        const customShape = new THREE.Mesh(geometry, material);
        customShape.rotation.x = Math.PI / 4;
        customShape.rotation.y = Math.PI / 4;
        scene.add(customShape);
        shapeRef.current = customShape;

        const handleResize = () => {
            if (mountRef.current) {
                camera.aspect = mountRef.current.clientWidth / mountRef.current.clientHeight;
                camera.updateProjectionMatrix();
                renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
            }
        };
        window.addEventListener('resize', handleResize);

        const handleScroll = () => {
            if (shapeRef.current) {
                const scrollY = window.scrollY;
                const rotationSpeed = 0.001; // Adjust the speed as needed
                shapeRef.current.rotation.y = Math.PI / 4 + scrollY * rotationSpeed;
            }
        };
        window.addEventListener('scroll', handleScroll);

        const animate = () => {
            requestAnimationFrame(animate);
            renderer.render(scene, camera);
        };
        animate();

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
            if (mountRef.current) {
                mountRef.current.removeChild(renderer.domElement);
            }
            geometry.dispose();
            material.dispose();
            renderer.dispose();
        };
    }, []);

    return <div ref={mountRef} className="low-poly-container"></div>;
};

export default LowPolyLines;



